////
/// @group _mixins/util-gutters.scss
////

/// Хелперы отступов - `margin` и `padding`
/// @param {Boolean} $prefix [''] - префикс
/// @param {Boolean} $in-rems [true] - значения в `rem'ax`
@mixin util-gutters($prefix: '', $in-rems: true) {
	$sides: (t: top, r: right, b: bottom, l: left);
	$property: (m: margin, p: padding);
	$gutter: $gutter;

	@if $in-rems {
		$gutter: rem($gutter);
	}

	@each $prop-name, $prop in $property {
		$start: #{$prefix}#{$prop-name};
		@each $factor-name, $factor in $gutter-factor {
			._#{$start}#{$factor-name} {
				#{$prop}: $gutter*$factor !important;
			}
			._#{$start}tb#{$factor-name} {
				#{$prop}-top: $gutter*$factor !important;
				#{$prop}-bottom: $gutter*$factor !important;
			}
			._#{$start}lr#{$factor-name} {
				#{$prop}-left: $gutter*$factor !important;
				#{$prop}-right: $gutter*$factor !important;
			}
		}
		@each $short-side, $side in $sides {
			@each $factor-name, $factor in $gutter-factor {
				._#{$start}#{$short-side}#{$factor-name} {
					#{$prop}-#{$side}: $gutter*$factor !important;
				}
			}
		}
	}

	._#{$prefix}m#{$delimiter}auto {
		margin: auto !important;
	}
	._#{$prefix}ml#{$delimiter}auto {
		margin-left: auto !important;
	}
	._#{$prefix}mr#{$delimiter}auto {
		margin-right: auto !important;
	}
	._#{$prefix}mlr#{$delimiter}auto {
		margin-left: auto !important;
		margin-right: auto !important;
	}
}

/// Хелперы отступов - `margin` и `padding` - для медиа запросов
/// @param {Boolean} $in-rems [true] - значения в `rem'ax`
/// @param {Map} $points [$breakpoints] - карта брейкпоинтов
@mixin util-gutters-mq($in-rems: true, $points: $breakpoints) {
	@each $point-name, $point in $points {
		@include media($point-name) {
			$prefix: #{$point-name}#{$delimiter};
			@include util-gutters($prefix, $in-rems);
		}
	}
}