////
/// @group _partials/form.scss
////


// scss
// ================================

.view-form {
	position: relative;
	background: #fff;	
	padding: 2em;
	box-shadow: 10.8px 28px 54px rgba(0, 0, 0, 0.24);
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 4px;
		background: #daa767;
	}	
	@media only screen and (min-width: 720px) {
		padding: 2.5rem 4rem 3.5rem;
	}

}

.form {
	position: relative;
	font-size: 1rem;
	@include clearfix();

	&__col {
	    position: relative;
	    max-width: 445px;
	    margin: 0 auto;
	 //    & + & {
		//     float: right;
		//     padding-left: 5%;
		//     width: 52.5%;
		// }
		@media only screen and (min-width: 720px) {
		    float: left;
		    width: 47.5%;
	    	& + & {
			    float: right;
			    padding-left: 5%;
			    width: 52.5%;
			}			
		}

	}

	&__group {
		display: block;
		position: relative;
		margin: 0 0 1.6rem;
		& & {
			margin: 0 0 .8rem;
		}
		&:last-child {
			margin-bottom: 0;
		}
		&._price,
		&._area {
			padding: 0 0 10px;
			border-bottom: 1px solid #e5e5e5;
			.control-holder {
				&--text {
					display: inline-block;
					vertical-align: top;
					max-width: 110px;
					width: 40%;
					padding: 10px 0;
					&:last-child {
						position: relative;
						margin-left: 20px;
						&:before {
							content: '';
							position: absolute;
							top: 50%;
							left: -16px;
							width: 8px;
							height: 1px;
							background: #bbbfc2;
						}
					}	
					input {
						min-height: 0;
    					line-height: 2em;
    					border: 1px solid #afafaf;
					}				
				}
			}
		}
	}

	&__label {
		cursor: default;
		display: inline-block;
		vertical-align: top;
		user-select: none;
		margin-bottom: .4rem;
	}

	&__caption {
		font-size: .8em;
		margin-bottom: .4em;
	}

	&__info {
		display: none;
		pointer-events: none;
		position: absolute;
		top: 0;
		right: 0;
		font-size: .7em;
		background-color: #000;
		color: #fff;
		padding: .1em .3em;
		input:focus ~ &,
		textarea:focus ~ &,
		select:focus ~ & {
			display: block;
		}
	}
}

label.form__label {
	display: block;
	cursor: pointer;
	font-weight: bold;
	font-size: 12px;
	text-transform: uppercase;
	margin-bottom: .8em; 
	i {
		font-weight: normal;
		text-transform: none;
	}
}


.control-holder {
	position: relative;

	label.has-error {
		position: absolute;
		top: 100%;
		left: 0;
		display: inline-block;
		text-align: center;
		user-select: none;
		font-size: .7em;
		line-height: 1em;
		padding: .1em .5em;
		border-radius: 2px;
		background-color: red;
		color: #fff;
	}

	&--text {
		input, textarea, select {
			@extend %buttonsGeometry;
			display: block;
			width: 100%;
			border: 1px solid #d1d1d1;
			border-radius: 4px;
			font-size: 13px;
			padding: $button-gap ($button-gap * 4);
			color: #6a6a6a;
			letter-spacing: 0px;
			font-weight: 400;
			text-transform: none;
			background: #fff;
			// width: 80%;
			// width: calc(100% - #{$button-gap * 6});
			// box-sizing: content-box;
		}
		textarea {
			resize: none;
			// min-height: 7em;
			// max-height: 18em;
			height: 9.25em;
		}

		@each $point-name, $point in $button-sizes {

			$point-size: $point-name + $delimiter + 'size';
			&-#{$point-size} {
				input, textarea, select {
					font-size: $point;
				}
			}
		}
	}

	&--flag {
		padding: 8px 0;
		border-bottom: 1px solid #e5e5e5;
		&._columns {
			label {
				display: inline-block;
				vertical-align: top;
			}
		}
		label {
			display: block;
			user-select: none;
			margin-right: 1em;
			cursor: pointer;
			position: relative;
			z-index: 1;
			margin-bottom: 0.8em;
			&:only-child,
			&.has-error {
				cursor: default;
				margin-right: 0;
			}
			span {
				display: inline-block;
				vertical-align: top;
				font-size: 13px;
				color: #616161;
				padding-top: 7px;
			}
			&:hover {
				ins {
					border-color: #daa767;					
				}
			}
		}
		ins {
			position: relative;
			display: inline-block;
			vertical-align: text-bottom;
			width: 2.2em;
			height: 2.2em;
			background-color: #fff;
			border: 1px solid #afafaf;
			border-radius: 4px;
			margin-right: 0.5em;
			transition: all 0.2s linear;			
		}
		input {
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			opacity: 0;
			z-index: -1;
			&[type="radio"] {
				~ ins {
					border-radius: 50%;
				}
			}
			&:checked {
				~ ins {
					background-color: #daa767;
					box-shadow: inset 0 0 0 6px #fff;
					border-radius: 4px;
				}
				~ span {
					color: #000;
					// font-weight: bold;
				}
			}
			&:disabled {
				~ ins,
				~ span {
					opacity: .5;
					cursor: not-allowed;
				}
			}
		}		
	}

	&--file {
		label {
			position: relative;
			display: inline-block;
			vertical-align: top;
		}

		input {
			position: absolute;
			outline: none;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			opacity: 0;
		}

		._ellipsis {
			max-width: 200px;
			width: auto;
			display: inline-block;
			vertical-align: top;
		}
	}
}
