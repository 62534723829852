////
/// @group _partials/breadcrumbs.scss
////

// scss
// ================================

.breadcrumbs {
	width: 100%;
	display: table;
	font-size: 0;
	line-height: 0;
	margin: 0 0 1rem;
	padding: 1rem 0;
	font-family: 'Verdana', Geneva, Tahoma, sans-serif;
}

.breadcrumbs a {
	color: #889196;
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}

.breadcrumbs span {
	color: #2f3940;
	display: inline-block;
	vertical-align: top;
	font-size: 0.9rem;
	line-height: 1.4;
	margin-right: .5em;

	&:before {
		margin-right: .5em;
		content: '/';
		display: inline-block;
		vertical-align: top;
		color: #889196;
	}

	&:first-child:before {
		display: none;
	}
}

.breadcrumbs {
	@include media( $md, max ) {
		display: block;

		span {
			&, &:before {
				display: none;
			}

			&:nth-last-child(2) {
				display: block;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: 100%;
				background: none;
				margin: 0;

				&:before {
					content: '<';
					margin: 0 5px 0 3px;
					display: inline-block;
				}
			}
		}
	}
}
