////
/// @group _partials/title.scss
////


// scss
// ================================

.title {
	line-height: 1.2;
	font-weight: bold;
	color: #000;
	font-size: 1.8rem;
	> p {
		margin: 1em 0 2em;
	}
	> a {
		display: inline-block;
		vertical-align: top;
		padding: 0 30px 0 0;
		font: 12px/1.2em 'Verdana', Geneva, Tahoma, sans-serif;
		color: #daa767;
		span {
			position: relative;
			padding: 0 10px;
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 100%;
				background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAFAgMAAAA8mPSdAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAADFBMVEXap2f////ap2f///9CwI8jAAAAAnRSTlMAAHaTzTgAAAABYktHRAH/Ai3eAAAACXBIWXMAAAsSAAALEgHS3X78AAAAGklEQVQI12NgAIKwABDJEDqBYRUILIDxIHIAdOcHCShAJHAAAAAASUVORK5CYII=') 0 0 no-repeat;
				width: 22px;
				height: 5px;
				margin-top: -2.5px;
			}
		}
	}
	> span {
		display: inline-block;
		vertical-align: top;
		font: italic 14px/1.6em 'Verdana', Geneva, Tahoma, sans-serif;
		color: #889196;
		margin: -1.75em 40px;
	}
	&:first-child,
	.breadcrumbs + & {
		> p {
			margin-top: 0;			
		}
	}
	@media only screen and (max-width: 1360px) {
		font-size: 1.7rem;
	}
	@media only screen and (max-width: 1024px) {
		font-size: 1.6rem;
		> a {
			font-size: 11px;
		}
		> span {
			font-size: 13px;
		}
	}
	@media only screen and (max-width: 768px) {
		font-size: 1.5rem;
		> a {
			font-size: 10px;
		}
		> span {
			font-size: 12px;	
			margin-bottom: 10px;		
		}
	}
	@media only screen and (max-width: 600px) {
		font-size: 1.3rem;
		> a {
			font-size: 9px;			
		}
		> span {
			font-size: 11px;		
		}
	}
}

// размеры
.title {
	&--sup {
		font-size: 2.5rem;
		> p {
			margin: 1.3em 0;
		}
		@media only screen and (max-width: 1360px) {
			font-size: 2.3rem;
		}
		@media only screen and (max-width: 1024px) {
			font-size: 2rem;
		}
		@media only screen and (max-width: 768px) {
			font-size: 1.8rem;
		}
		@media only screen and (max-width: 600px) {
			font-size: 1.6rem;
		}
	}

	&--sub {
		font-size: 1.5rem;
		@media only screen and (max-width: 1360px) {
			font-size: 1.4rem;
		}
		@media only screen and (max-width: 1024px) {
			font-size: 1.3rem;
		}
		@media only screen and (max-width: 768px) {
			font-size: 1.1rem;
		}
		@media only screen and (max-width: 600px) {
			font-size: 1rem;
		}
	}
}

// цвета
.title {
	&--white {
		color: #fff;
	}
}




