////
/// @group _partials/view-footer.scss
////


// scss
// ================================

.view-footer {
	position: relative;
	// background: #1d2b34 url('pic/bg_view-footer.jpg') center center no-repeat;
	background-size: cover;
	// width: 1920px;
	// height: 314px;
	// padding: 50px 0;
	padding: 1em 0;
	box-sizing: border-box;
	&__in {
		// padding: 0 50px;
		padding: 0 1%;
	}
	&__content {
		text-align: center;
	}
	&__logo {
		max-width: 302px;
		// width: 80%;
		padding-right: 20%;
		// margin: 0 0 50px;
		margin: 0 0 10%;
		span {
			display: block;
			width: 100%;
		}
	}
	&__copy {
		font: 11px/1.2em 'Verdana', Geneva, Tahoma, sans-serif;
		color: #999999;
		padding-right: 20px;
	}
	&__main {
		display: none !important;
	}
	&__column {
		position: relative;
		padding-right: 80px;
		// padding: 0 40px;
		> p {
			font: bold 10px/1.8em 'Verdana', Geneva, Tahoma, sans-serif;
			color: #ffffff;
			height: 3.6em;
			text-transform: uppercase;
			// margin: 0 0 30px;
			margin: 0 0 10px;
		}
		> ul {
			font-size: 0;
			line-height: 0;
			padding: 0;
			margin: 0;
			li {
				display: block;
				a {
					position: relative;
					display: inline-block;
					vertical-align: top;
					font: 12px/2em 'Verdana', Geneva, Tahoma, sans-serif;
					color: #858e94;
					text-decoration: none;
					transition: all 0.2s linear;
					&:hover {
						color: #eebe81;
						text-decoration: underline;
					}
				}
			}
		}
		&--info {
			// padding-right: 80px;
			> ul {
				li {
					display: inline-block;
					vertical-align: top;
					width: 50%;	
					min-width: 125px;
				}
			}
		}
		&--addresses {
			.list-addresses {}
			.address-item {
				margin-bottom: 25px;					
			}
			span, a {
				display: inline-block;
				vertical-align: top;					
			}
			span {
				font: 12px/1.6em 'Verdana', Geneva, Tahoma, sans-serif;
				color: #858e94;
			}
			a {
				font: 11px/1.2em 'Verdana', Geneva, Tahoma, sans-serif;
				color: #eebe81;
				&:hover {
					text-decoration: none;
				}
			}
			.social {
				position: relative;
				ul {
					font-size: 0;
					line-height: 0;
					margin: 0;
					list-style: none;
					padding: 0;
					li {
						display: inline-block;
						vertical-align: middle;
						width: 30px;
						height: 30px;
						margin: 0 5px;
						a {
							position: relative;
							display: block;
							border: 1px solid rgba(255,255,255,.2);
							// background: #fff;
							background: transparent;
							border-radius: 8px;
							overflow: hidden;
							width: 100%;
							height: 100%;
							transition: all 0.2s linear;
							svg {
								display: block;
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
								width: 100%;
								height: 100%;
								max-width: 20px;
								max-height: 20px;
								margin: auto;
								fill: rgba(255,255,255,.5);
							}
							&:hover {
								background: #eebe81;
								svg {
									fill: #000;
								}
							}
						}
					}
				}
			}
		}
		&--phones {
			padding-right: 0;
			.list-phones {
				position: relative;
				// margin: 0 0 20px -20px;
				// margin: 0 0 20px -10px;		
				margin: 0 0 20px 0;		
			}
			ul {
				font-size: 0;
				line-height: 0;
				margin: 0;
				padding: 0;
				list-style: none;
				text-align: left;
				li {
					position: relative;
					display: inline-block;
					vertical-align: top;
					width: 50%;
					min-width: 160px;
					// padding: 0 20px;
					padding: 0 5px;
					box-sizing: border-box;
					a {
						display: inline-block;
						vertical-align: top;
						font: 11px/2.2em 'Verdana', Geneva, Tahoma, sans-serif;
						color: #b3babe;
						text-decoration: none;
						transition: color 0.2s linear;
						span {
							font-size: 14px;
							font-weight: bold;
							color: #ffffff;
						}	
						&:hover {
							color: #fff;
						}				
					}
				}
			}
			.callback {
				position: relative;
				white-space: nowrap;
				.button {
					font-size: 0.7em;
				}
			}
		}

	}

	

	@media only screen and (min-width: 320px) {
		text-align: center;
		background: #1d2b31;
		&__logo {			
			margin: 0 auto 20px;
			padding-right: 0;
			max-width: 205px;
		}
		&__copy {
			padding: 0;
		}
	}

	@media only screen and (min-width: 1024px) {
		padding: 3.5em 0; 
		background: url('pic/bg_view-footer.jpg') center center no-repeat;
		background-size: cover;
		&__main {
			display: inline-block !important;
		}
		&__logo {
			width: 100%;
			max-width: 302px;
			margin: 0 0 10%;
		}
		&__copy {
			text-align: left;
		}

		&__column {
			padding: 0 20px;
			&--phones {
				ul {
					text-align: center;
					li {
						text-align: center;
					}
				}
			}
		}
	}

	@media only screen and (min-width: 1280px) {
		&__content {
			text-align: left;
		}		
		&__column {
			padding: 0 20px;
			&--phones {
				ul {
					text-align: left;
					li {
						text-align: center;
					}
				}
			}
		}
	}


	@media only screen and (min-width: 1360px) {
		&__copy {
			font-size: 12px;
			// padding-right: 0;
		}
		&__column {
			> ul {
				li {
					a {
						font-size: 12px;
					}
				}
			}	
			&--addresses {
				span {
					font-size: 12px;
				}	
				a {
					font-size: 11px;
				}			
			}	
			&--phones {
				ul {
					text-align: left;
					li {
						text-align: left;
						a {
							font-size: 12px;
							span {
								font-size: 14px;
							}
						}
					}
				}
				.callback {
					.button {
						font-size: 0.8em;
					}
				}
			}	
		}

	}

	@media only screen and (min-width: 1440px) {
		&__in {
			padding: 0 2.6%;
		}
		&__logo {
			width: 100%;
			padding-right: 0;
		}
		&__column {
			position: relative;
			padding: 0 30px 0 0;
			> p {
				font-size: 11px;
			}
			> ul {
				li {
					// display: block;
					a {
						font-size: 13px;
					}
				}
			}
			&--addresses {
				span {
					font-size: 13px;
				}	
				a {
					font-size: 12px;
				}			
			}
			&--phones {
				.list-phones {
					&:after {
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						width: 1px;
						height: 36px;
						background: rgba(255,255,255,.16);
						margin-top: -18px;
					}
				}
				ul {
					li {
						a {
							font-size: 13px;
							span {
								font-size: 15px;
							}
						}
					}

				}
			}
		}
	}

	@media only screen and (min-width: 1660px) {
		&__column {
			padding: 0 80px 0 0;
			> ul {
				li {
					a {
						font-size: 14px;
					}
				}
			}
			&--phones {
				padding-right: 0;
				ul {
					li {
						a {
							font-size: 14px;
							span {
								font-size: 16px;
							}
						}
					}
				}
			}
			&--addresses {
				span {
					font-size: 14px;
				}	
				a {
					font-size: 13px;
				}			
			}
		}
	}


}
