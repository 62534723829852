////
/// @group _partials/view-section.scss
////


// scss
// ================================

.index-page {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	.jk__hide-form {
		display: none;
	}
	.preloader { 
	 	.toucheevents & {
	 		display: none;
	 	}
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 100;
		opacity: 1;
		visibility: visible;
		transition: all 0.9s 6s linear; 
		&-part {
			position: absolute;
			width: 50%;
			height: 50%;
			// background: #fff;
			// border: 1px solid #fff;
			transition: transform 0.9s 5s cubic-bezier(0.7,0,0.3,1);
			&:nth-child(2), 
			&:nth-child(4) {
			    left: 50%;
			}
			&:nth-child(3), 
			&:nth-child(4) {
			    top: 50%;
			}
			&__bg {
				display: block;
				position: absolute;
				width: 100%;
			    height: 100%;
			    background: #fff;
			}
		}
		&-logo {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100px;
			height: 100px;			
			margin: -50px 0 0 -50px;
			border-radius: 50%;
			background: #fff;
			box-shadow: 0 0 10px 1px rgba(209,165,106, .8);


			@media only screen and (min-width: 720px) {
				width: 120px;
				height: 120px;
				margin: -60px 0 0 -60px;
			}


			@media only screen and (min-width: 1024px) {
				width: 160px;
				height: 160px;
				margin: -80px 0 0 -80px;
			}

			@media only screen and (min-width: 1360px) {
				width: 200px;
				height: 200px;			
				margin: -100px 0 0 -100px;
			}

			> div {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				width: 80%;
				height: 80%;
				margin: auto;
				background: url('pic/logo-body.svg') center center no-repeat;	
	  			animation: rotate 4s infinite;

			}

			> span {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				width: 35%;
				height: 35%;
				margin: auto;
				background: url('pic/logo-S.svg') center center no-repeat;
	  			animation: pulse 2s infinite;
			}
			@keyframes rotate {
			    0% { 
			    	transform: rotate(0); 
			    }
			    50% { 
			    	transform: rotate(360deg); 
			    }
			    100% { 
			    	transform: rotate(0); 
			    }
			}
			@keyframes pulse {
			    0% { 
			    	transform: scale(1); 
			    }
			    50% { 
			    	transform: scale(1.1); 
			    }
			    100% { 
			    	transform: scale(1); 
			    }
			}
		}
	}

	&.is-loaded {
		overflow: visible;
		height: auto;
		.b-container {
			transform: scale(1);
		}
		.preloader {
			visibility: hidden;
			opacity: 0;
			&-part {
				&:nth-child(1) {
					transform: translate3d(-90%, -90%, 0);						
				}
				&:nth-child(2) {
					transform: translate3d(90%, -90%, 0);						
				}
				&:nth-child(3) {
					transform: translate3d(-90%, 90%, 0);						
				}
				&:nth-child(4) {
					transform: translate3d(90%, 90%, 0);						
				}
			}
		}
	}
}




.view-section {
	position: relative;
	padding: 60px 0 0;
	box-sizing: border-box;
	background: #fff;
	// @media only screen and (min-width: 1024px) {
	// 	padding: 60px 0 0;
	// }
	@media only screen and (min-width: 1024px) {
		padding: 40px 0 0;
	}

	&--main {
		padding: 0 0 30px 0;
		&._bg {
			background-color: #fff;
			background-image: url(pic/bg_view-section--newbuilding.jpg);
			background-repeat: no-repeat;
			&--top {
				background-position: center top;
			}
			&--bottom {
				// background-color: #eff4f8;
				background-position: center bottom;
			}
		}
		& + .view-section--slider {
			margin-top: -60px;
		}
		@media only screen and (min-width: 1024px) {
			padding: 30px 0 60px;
		}
		&.jk {
			@media only screen and (min-width: 1024px) {
				padding-bottom: 130px;				
			}
		}

	}

	&--top {
		height: auto;
		padding-top: 0;
		display: none;
		@media only screen and (min-width: 1024px) {
			display: block;
			&__bg {
				display: block;
				// padding-top: 288px;
				padding-top: 15%;
				background: url('pic/bg_view-section--top.jpg') center top no-repeat;
				background-size: 100%;
				&._jk {
					padding-top: 37%;
					background: url('pic/bg_view-section--top_jk.jpg') center top no-repeat;
					background-size: 100%;
				}
			}			
		}
		&__info {
			position: absolute;
			bottom: 20px;
			left: 20px;
			.item-label {
				position: relative;
				display: inline-block;
				height: 40px;
				padding-left: 40px;
				z-index: 12;
				background: rgba(33,46,56,.8);
				border-top-left-radius: 20px;
				border-bottom-left-radius: 20px;
				transition: 0.3s linear;
				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 100%;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 40px 0 0 40px;
					border-color: transparent transparent transparent rgba(33,46,56,.8);
				}
				&__icon {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 40px;
					height: 40px;
					border-radius: 20px;
					// background: #1f98dd;
					transition: 0.3s linear;
					svg {
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						margin: auto;
						width: 60%;
						height: 60%;
						fill: #fff;
					}
				}
				&__text {
					display: inline-block;
					vertical-align: middle;
					padding: 12px 20px;
					font: bold 14px/1.2em 'Verdana', Geneva, Tahoma, sans-serif;
					color: #ffffff;
					text-transform: uppercase;
				}
				> p {
					display: inline-block;
					vertical-align: middle;
					font: 14px/1.2em 'Verdana', Geneva, Tahoma, sans-serif;
	    			color: #fff;
					text-transform: uppercase;	    			
	    			margin: 0;
	    			padding: 0 10px;
				}
				&--action {
					.item-label {
						&__icon {
							background: #1f98dd;															
						}
					}
				}
				&--design {
					.item-label {
						&__icon {
							background: #01b39c;											
						}
					}
				}
				&--status {
					padding: 0.4em;
					height: auto;
					border-radius: 0;
					.item-label {
						&__text {
						    padding: 1em;
						    font: bold 15px/1.2em 'Verdana', Geneva, Tahoma, sans-serif;
						    color: #ffffff;
						    text-transform: uppercase;
						    // background: #1f98d1;
						}
					}
					&:after {
						border-width: 60px 0 0 60px;
					}
				}
			}
			// &:hover {
			// 	.item-label {
			// 		padding: 0 30px 0 0;
			// 		&__icon {
			// 			left: calc(100% - 30px);
			// 		}
			// 	}
			// }
			
		}


		&.index-top {
			padding: 0 !important;
			margin: 0;
			// margin-top: -60px;
			height: calc(100vh - 60px);
			background: none;
			display: block;
			@media only screen and (min-width: 1024px) {
				height: 100vh;		
			}			
		}
	}

	// &--pagination {
	// 	padding: 20px 0;
	// }

	&--map {
	}

	&--newbuilding {
		background: #fff;
		padding-bottom: 80px;
		// background-size: 100%;
		// height: 1023px;
		@media only screen and (min-width: 1024px) {
			background: #fff url('pic/bg_view-section--newbuilding.jpg') left center no-repeat;
			padding-bottom: 50px;
		}


		.w-tabs {
			transform: translateZ(0);
			&__header {
				position: relative;			    
			    display: flex;
			    // padding-top: 30px;
			    padding-top: 2%;
			    // margin-bottom: 70px;
			    margin-bottom: 5%;
			    text-align: center;
			    ._line {
			    	position: relative;
			    	&:before {
			    		content: '';
			    		position: absolute;
			    		bottom: 14px;
			    		left: 0;
			    		width: 100%;
			    		height: 1px;
			    		background: #dadedf;
			    	}
			    }
			}

			&__link {
				position: relative;
			    display: inline-block;
			    padding-bottom: 50px;
			    text-decoration: none;
			    font: bold 14px/1.2em 'Verdana', Geneva, Tahoma, sans-serif;
				color: #000;
			    transition: 0.2s linear;
			    cursor: pointer;
			    backface-visibility: hidden;
				&:before,
				&:after {
					content: '';
					position: absolute;
					left: 50%;
					border-radius: 50%;
					transform: translateX(-50%);
					transition: all 0.2s linear;
				}
				&:before {
					bottom: 0;
					background: #fff;
					border: 1px solid #daa767;
					width: 30px;
					height: 30px;
					opacity: 0;
				}

				&:after {
					bottom: 5px;
					background: #fff;
					border: 1px solid #727d84;
					width: 20px;
					height: 20px;					
				}

				&:hover {
					color: #daa767;
					&:before {
						opacity: 1;
					}
					&:after {
						background: #daa767;
						border-color: #daa767;
					}
				}			    
			    &--is-active {
			    	color: #989898;
					&:after {
						border-color: #e0e0e0; 
						background: #e0e0e0;

					}			    	
			    }
				@media only screen and (max-width: 768px) {
					margin-bottom: 15px;
					padding-bottom: 35px;
					font-size: 12px;
				}

			}

			&__body {
				position: relative;
			    padding-top: 1rem;
			    min-height: 100px;
			}

			&__container {
				display: block;
			    transform: translateZ(0);
				.list-block-items {
					transform: translateY(5%) scale(0.95);
					opacity: 0;
					height: 0;
					overflow: hidden;
					// visibility: hidden;
					transition: opacity 0.3s 0.1s linear, transform 0.3s 0.1s linear;					
				}
				&--is-active {
					.list-block-items {
					    height: auto;
						// visibility: visible;
						overflow: visible;
					    transform: translateY(0) scale(1);
					    opacity: 1;						
					}

				}
			}

		}
	}

	&--promotional-offers {
		background: url('../css/pic/bg_view-section--promotional-offers.jpg') left center no-repeat;
		// height: 742px;
		// padding-bottom: 80px;
		padding-bottom: 3em;
		@media only screen and (min-width: 1024px) {
			padding-bottom: 6em;
		}

	}

	&--consultation {
		// height: 649px;
		padding: 45px 0;
		background: #f8f8f8;
		@media only screen and (min-width: 1024px) {
			padding: 90px 0;
		}
		@media only screen and (min-width: 1280px) {
			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				background: url('../css/pic/bg_view-section--consultation.png') 0 0 no-repeat;
				width: 791px;
				height: 100%;
				background-size: 100%;
			}				
		}

	}

	&--slider {
		// padding: 40px 0 60px;
		@media only screen and (min-width: 1024px) {
			padding: 40px 0 60px;
		}
		&._gray {
			background: #f2f5f7;
		}
	}

	&--order-service {
		background: #26424e;
		padding: 3.5% 0;
		// height: 683px;
		// margin-bottom: 70px;
		@media only screen and (min-width: 1024px) {
			background: url('../css/pic/bg_view-section--order-service.jpg') center top no-repeat;
			padding: 3.5% 0 7.5%;
		}
	}

	&--questions {
		background: transparent;
		// margin-bottom: 70px;
		margin-bottom: 3.7%;
		margin-top: 3%;
		padding: 3.5% 0 0;
		@media only screen and (min-width: 1024px) {
			// margin-top: -105px;
			margin-top: -5.5%;
			padding: 0;
		}
	}

}

.view-subsection {
	position: relative;
	&--jk-top {
		// padding: 50px 0 60px;
		padding: 2.8% 0 3%;
		background-color: #ebeff2;
		box-shadow: inset 0 14px 62px rgba(31, 38, 41, 0.13);
	}	
	&--jk-variants {
		padding: 50px 0;
	}
	&--jk-content {
		padding: 40px 0;
		background: #f2f5f7;
		.content {
			 > div:not(.content__link) {
				border: none;
			}			
		}
	}
	&--jk-benefits {
		// padding: 50px 0 0;
		padding: 3% 0 0;
	}
}


.top-slider {
	position: relative;
	padding-top: 0;
	// padding-top: 50%;
	height: auto;
    // height: 964px;
    overflow: hidden;
	background: transparent;
	&__in {
	    position: fixed;
	    top: 60px;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    height: 100%;
	    width: 100%;
	    z-index: 0;
	    margin-bottom: 0 !important;
		@media only screen and (min-width: 1024px) {
			top: 0;
		}

	}  
	&__slide {
		position: relative;
		&:before {
			display: none;
			content: '';
			position: absolute;
	    	top: 0;
	    	left: 0;
	    	width: 100%;
	    	height: 100%;
	    	background: #1e303b;
	    	opacity: 0.16;
	    	transition: all 0.5s linear;
		}
	}  
	&__content {
		display: inline-block;
		position: absolute;
		top: 50%;
		left: 50%;
		text-align: center;
		font-family: 'Verdana', Geneva, Tahoma, sans-serif;
		color: #fff;
		transform: translate(-50%,-50%);
		z-index: 15;
		// opacity: 0;
		// visibility: hidden;
		transition: all .25s linear;
		opacity: 0;

		.is-show-slider & {
			opacity: 1;
			transition-delay: .25s;	
		}

		> span {
			display: block;
			font-size: 30px;
			line-height: 1.2em;
			margin: 0 0 15px;
		}
		> p {
			font-size: 75px;
			line-height: 1.2em;			
			text-transform: uppercase;
			font-weight: bold;
			margin: 0 0 45px;
		}

	}
	.cs {
		&-container {
			position: static !important;
			height: 100%;
		}
		&-nav-cont {
			position: absolute;
	    	top: 50%;
	    	left: auto;
	    	bottom: auto;
	    	right: 50px;
	    	width: 35px;
	    	transform: translateY(-50%);
	    	z-index: 10;
	    	span {
	    		position: relative;
    			display: block;
    			margin: 0 0 20px;
    			border-radius: 50%;
			    cursor: pointer;
			    padding: 8px 0;
			    height: 35px;
			    width: 35px;
			    text-indent: 0;
			    text-align: center;
			    background: transparent;
			    border: 1px solid #fff;
			    font: bold 14px/1.2em 'Verdana', Geneva, Tahoma, sans-serif;
				color: #ffffff;
				transition: all 0.2s linear;
				&:hover,
			    &.cs-current {
		    		background: #fff;
		    		color: #4e4e4e;	
			    }
			    &.cs-current {
			    	&:after {
			    		content: '';
			    		position: absolute;
			    		top: 50%;
			    		left: 100%;
			    		width: 100px;
			    		height: 1px;
			    		background: #fff;
			    	}
			    }
	    	}
		}
	}
	.camera {
    	&_wrap {
    		.camera {
			    &_overlayer {
			    	position: absolute;
			    	top: 0;
			    	left: 0;
			    	width: 100%;
			    	height: 100%;
			    	background: #1e303b;
			    	opacity: 0.3;
			    }
			    &_pag {
			    	position: absolute;
			    	top: 50%;
			    	right: 50px;
			    	transform: translateY(-50%);
			    	z-index: 100;
			    	&_ul {
			    		li {
						    position: relative;
			    			display: block;
			    			border-radius: 50%;
						    cursor: pointer;
						    height: 35px;
						    width: 35px;
						    text-indent: 0;
						    text-align: center;
						    background: transparent;
						    border: 1px solid #fff;
							transition: all 0.2s linear;
						    > span {
							    position: absolute;
								overflow: hidden;
						    	border-radius: 50%;
							    top: 0;
							    left: 0;
							    bottom: 0;
							    right: 0;
							    margin: auto;
							    height: 100%;
							    width: 100%;
							    line-height: 32px;
							    background: transparent;							    
							    transition: all 0.2s linear;
							    span {
							    	display: inline-block;
							    	vertical-align: middle;
							    	font: bold 14px/1.2em 'Verdana', Geneva, Tahoma, sans-serif;
							    	color: #ffffff;
							    }
						    }
						    &:hover,
						    &.cameracurrent {
						    	> span {
						    		background: #fff;
						    		span {
						    			color: #4e4e4e;						    								    			
						    		}
						    	}
						    }
						    &.cameracurrent {
						    	&:after {
						    		content: '';
						    		position: absolute;
						    		top: 50%;
						    		left: 100%;
						    		width: 100px;
						    		height: 1px;
						    		background: #fff;
						    	}
						    }

			    		}
			    	}
			    }	
    		}	    		
    	}
    }
}

.cs-nav-cont {
	counter-reset: section;

	span::before {
	    counter-increment: section;
	    content: counter(section);
	}
}

.list-block-items {
	position: relative;
	.block-item {
		position: relative;
		max-width: 330px;
		border: 1px solid #e6e6e6;
		border-radius: 5px;
		background: #fff;
		text-align: left;
		margin: 0 auto 25px;
		transition: all 0.2s linear;
		-webkit-backface-visibility: hidden;
		transform: translateZ(0);
		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			background: #f7d8b0;
			opacity: 0;
			visibility: hidden;
			transition: all 0.2s linear;
		}
		&:before {
			top: 0;
		}
		&:after {
			bottom: 0;
		}
		&__img {
			position: relative;
			// padding-top: 225px;
			padding-top: 68.5%;
			overflow: hidden;
			// margin-bottom: 15px;		
			margin: -1px -1px 4.5%;	
			backface-visibility: hidden;
			a {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;				
				background-repeat: no-repeat;
    			background-size: cover;
				transition: 0.2s linear;
				z-index: auto;
				// background: #e4e4e4;
				&:before {
				    content: '';
				    position: absolute;
				    left: 0;
				    top: 0;
				    width: 100%;
				    height: 20%;
				    opacity: 0;
				    transition: 0.2s linear;
				    background: -webkit-linear-gradient(top,rgba(0,0,0,.4) 10%,transparent 100%);
				    background: linear-gradient(to bottom,rgba(0,0,0,.4) 10%,transparent 100%);
				    z-index: 10;
				}	
				&:hover {
					&:before {
						height: 100%;
						opacity: 1;
					}
				}
			}
		}
		&__status {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			text-align: right;
			padding: 0.3em;
			background: rgba(33,46,56,0.6);
			span {
				display: inline-block;
				vertical-align: middle;
				padding: 0.7em;
				font: bold 11px/1.2em 'Verdana', Geneva, Tahoma, sans-serif;
    			color: #ffffff;
    			text-transform: uppercase;
    			// background: #1f98d1;
			}
		}
		&__content {
			padding: 0 20px 20px;
		}
		&__date {
			position: relative;
			border-bottom: 1px solid #e5e5e5;
			padding: 0 0 4.5% 0;
			margin-bottom: 4.5%;
			font: 12px/1.2em 'Verdana', Geneva, Tahoma, sans-serif;
			color: #898989;
		}
		&__title {
			// padding: 20px 0;
			// margin-bottom: 15px;
			margin-bottom: 7%;
			line-height: 3em;
			height: 3.2em;
			overflow: hidden;
			a {
				display: inline-block;
				vertical-align: middle;
				font: bold 13px/1.5em 'Verdana', Geneva, Tahoma, sans-serif;
				color: #000;
				text-decoration: none;
				text-transform: uppercase;
				transition: 0.2s linear;
				&:hover {
					color: #daa767;
					text-decoration: underline;
				}
				// @media only screen and (min-width: 1024px) {
				// 	font-size: 13px;
				// }
				@media only screen and (min-width: 1280px) {
					font-size: 14px;
				}

			}
		}
		&__price {
			position: relative;
			// margin: 0 -20px 15px;
			margin: 0 -20px 5%;
			padding: 10px;
			background: #f4e6d5;
			text-align: center;
			font: bold 14px/1.7em 'Verdana', Geneva, Tahoma, sans-serif;
			color: #000000;
			b {
				font-size: 25px;
				line-height: 1em
			}
		}
		&__description {
			position: relative;
			// margin-bottom: 25px;
			margin-bottom: 8%;
			min-height: 3.8em;
			ul {
				font-size: 0;
				line-height: 0;
				padding: 0;
				margin: 0;
				li {
					display: block;
					font-family: 'Roboto', Verdana, Geneva, Tahoma, sans-serif;
					color: #545454;
					padding: 5px 0;
					span {
						font-size: 15px;
						line-height: 1.2em;
						margin-left: 5px;
					}
					b {
						font-size: 13px;
						line-height: 1.2em;						
					}
				}
			}
		}
		&:hover {
			box-shadow: 0 25px 57px rgba(44, 56, 61, 0.16);
			&:before,
			&:after {
				opacity: 1;
				visibility: visible;				
			}
			&:before {
				transform: translateY(-12px);
			}
			&:after {
				transform: translateY(12px);
			}
		}
		.item-label {
			display: inline-block;
			position: absolute;
			top: 10px;
			left: 10px;
			height: 30px;
			padding-left: 30px;
			z-index: 12;
			background: #1d2b34;
			border-radius: 15px;
			transition: 0.3s linear;
			& + .item-label {
				top: 50px;
			}			
			&__icon {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 30px;
				height: 30px;
				border-radius: 15px;
				// background: #1f98dd;
				transition: 0.3s linear;
				svg {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					margin: auto;
					width: 60%;
					height: 60%;
					fill: #fff;
				}
			}
			&__text {
				display: inline-block;
				vertical-align: middle;
				padding: 9px 10px ;
				font: bold 11px/1.2em 'Verdana', Geneva, Tahoma, sans-serif;
				color: #ffffff;
				text-transform: uppercase;
			}
			&--action {
				.item-label {
					&__icon {
						background: #1f98dd;															
					}
				}
			}
			&--design {
				.item-label {
					&__icon {
						background: #01b39c;											
					}
				}
			}
		}
		&:hover {
			.item-label {
				padding: 0 30px 0 0;
				&__icon {
					left: calc(100% - 30px);
				}
			}
		}
	}
	&--services {
		.block-item {
			text-align: center;
			&__img {
				padding-top: 100%;			
			}
			&__title {
				margin: 0;
			}
		}
	}
}

.list-block-gallery {
	position: relative;
	// padding: 35px 0;
	overflow: hidden;
	margin-bottom: 25px;
	.block-item {
		position: relative;
		max-width: 335px;
		background: #fff;
		text-align: left;
		margin: 0 auto;
		transition: all 0.2s linear;
		-webkit-backface-visibility: hidden;
		transform: translateZ(0);
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			box-shadow: inset 0 0 0 1px #dfdfdf;
			z-index: 2;
		}
		&__img {
			position: relative;
			padding-top: 230px;
			// padding-top: 68.5%;
			overflow: hidden;
			z-index: 10;
			// margin-bottom: 15px;		
			// margin: -1px -1px 4.5%;
			-webkit-backface-visibility: hidden;			
			a {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				// background-size: 100%;
				background-size: contain;
			    background-repeat: no-repeat;
			    background-position: center;
				transition: 0.2s linear;
				z-index: auto;
				&:before {
				    content: '';
				    position: absolute;
				    left: 0;
				    top: 0;
				    width: 100%;
				    height: 20%;
				    opacity: 0;
				    transition: 0.2s linear;
				    background: rgba(35,43,50,.65);
				    z-index: 1;
				}
				.svg-icon {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					width: 29px;
					height: 29px;
					margin: auto;
					border-radius: 50%;
					fill: #fff;
					opacity: 0;
					transform: scale(0.2);
					z-index: 2;
					transition: all 0.2s linear;
					svg {
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						width: 100%;
						height: 100%;
					}
				}
			}
			.no-touchevents  &:hover {
				a {
					&:before {
						height: 100%;
						opacity: 1;
					}
					.svg-icon {
						transform: scale(1);
						opacity: 0.7;
					}					
				}
			}
		}
	}
}

.list-block-plans {
	position: relative;
	// padding: 35px 0;
	overflow: hidden;
	margin: 0 40px;	
	.block-item {
		position: relative;
		max-width: 180px;
		background: #fff;
		text-align: left;
		margin: 0 auto;
		transition: all 0.2s linear;
		-webkit-backface-visibility: hidden;
		transform: translateZ(0);
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
		}
		&__img {
			position: relative;
			// padding-top: 184px;
			// padding-top: 120px;
			padding-top: 68%;
			overflow: hidden;
			z-index: 10;
			-webkit-backface-visibility: hidden;			
			a {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;				
				background-repeat: no-repeat;
    			background-size: cover;
				transition: 0.2s linear;
				z-index: auto;
				&:before {
				    content: '';
				    position: absolute;
				    left: 0;
				    top: 0;
				    width: 100%;
				    height: 100%;
				    opacity: 0;
				    z-index: 1;
				    // border: 2px solid #c9ced2;
					box-shadow: inset 0 0 0 2px #c9ced2, 0 17px 38px rgba(0, 0, 0, 0.21);
				    transition: 0.2s linear;
				}
			}
			&:hover {
				a {
					&:before {
						opacity: 1;
					}				
				}
			}
		}
	}
}

.variants {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-top: 1px solid #fff;
		border-left: 1px solid #fff;

	}
	> div {
		border-top: 1px solid #e6e6e6;
		border-left: 1px solid #e6e6e6;
	}
}

.list-block-variants {
	position: relative;
	margin-bottom: 40px;
	.block-item {
		position: relative;
		// max-width: 470px;
		// padding: 55px;
		padding: 12% 7%;
		// border: 1px solid #e6e6e6;
		background: #fff;
		text-align: center;
		transition: all 0.2s linear;
		-webkit-backface-visibility: hidden;
		transform: translateZ(0);
		
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-top: 3px solid #daa767;
			background: #fff;
			opacity: 0;
			transform: scale(1.01);
			transition: all 0.2s linear;
		}
		&__img {
			position: relative;
			// padding-top: 150px;
			padding-top: 42.5%;
			// width: 150px;
			// padding-top: 68.5%;
			// background: #eee;
			overflow: hidden;
			margin: 0 auto 4.2%;
			-webkit-backface-visibility: hidden;

			a {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-size: 100%;
				z-index: auto;
				// background: #e4e4e4;
				filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
			    filter: gray;
			    -webkit-filter: grayscale(100%);
				transition: 0.2s linear;
			}
		}		
		&__title {
			position: relative;
			z-index: 2;			
			// margin-bottom: 25px;
			margin-bottom: 7%;
			height: 1.5em;
			overflow: hidden;
			a {
				display: inline-block;
				vertical-align: middle;
				font: bold 14px/1.5em 'Verdana', Geneva, Tahoma, sans-serif;
				color: #000;
				text-decoration: none;
				transition: 0.2s linear;
				&:hover {
					color: #daa767;
					text-decoration: underline;
				}
				@media only screen and (min-width: 1280px) {
					font-size: 17px;
				}
				@media only screen and (min-width: 1024px) {
					font-size: 15px;
				}
				

			}
		}

		&__content {
			position: relative;
			font-size: 0;
			line-height: 0;
			border-radius: 50%;
			z-index: 2;
			-webkit-backface-visibility: hidden;			
		}
		&__space,
		&__btn {
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: 50%;
			height: 48px;
		}
		&__space {
			background: #fff;
			// padding: 10px 5px;
			padding: 0 5px;
			border: 1px solid #c0c0c0;
			border-top-left-radius: 28px;
			border-bottom-left-radius: 28px;
			text-align: center;
			font: bold 14px/1.5em 'Verdana', Geneva, Tahoma, sans-serif;
			line-height: 46px;
			color: #000000;
			span {
				display: inline-block;
				vertical-align: middle;
			}
			b {
				font-size: 16px;
				line-height: 1em;
			}
		}
		&__btn {
			.button {
				width: 100%;
				border-radius: 0;
				border-top-right-radius: 28px;
				border-bottom-right-radius: 28px;								
			}
		}
		&:hover {
			// transform: scale(1.015);
			z-index: 1;
			box-shadow: 0 25px 57px rgba(44, 56, 61, 0.16);	
			&:before {
				opacity: 1;
			}
			.block-item {
				&__img {
					a {
						filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
    					-webkit-filter: grayscale(0%);
					}
				}
			}

		}
		.item-label {
			display: inline-block;
			position: absolute;
			// top: 30px;
			top: 9%;
			left: 4%;
			height: 30px;
			padding-left: 30px;
			z-index: 12;
			background: #1d2b34;
			border-radius: 15px;
			transition: 0.3s linear;
			& + .item-label {
				margin-top: 40px;
			}
			&__icon {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 30px;
				height: 30px;
				border-radius: 15px;
				// background: #1f98dd;
				transition: 0.3s linear;
				svg {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					margin: auto;
					width: 60%;
					height: 60%;
					fill: #fff;
				}
			}
			&__text {
				display: inline-block;
				vertical-align: middle;
				padding: 9px 10px ;
				font: bold 11px/1.2em 'Verdana', Geneva, Tahoma, sans-serif;
				color: #ffffff;
				text-transform: uppercase;
			}
			&--action {
				.item-label {
					&__icon {
						background: #1f98dd;															
					}
				}
			}
			&--design {
				.item-label {
					&__icon {
						background: #01b39c;											
					}
				}
			}
		}
		&:hover {
			.item-label {
				padding: 0 30px 0 0;
				&__icon {
					left: calc(100% - 30px);
				}
			}
		}

		@media only screen and (min-width: 1440px) {
			&__space {
				font-size: 16px;
				b {
					font-size: 20px;
				}
			}
		}

		// @media only screen and (min-width: 1360px) {
		// 	&__space {
		// 		// font-size: 16px;
		// 		b {
		// 			font-size: 20px;
		// 		}
		// 	}
		// }

		@media only screen and (min-width: 1280px) {
			&__space {
				font-size: 15px;
				b {
					font-size: 18px;
				}
			}
		}

		@media only screen and (min-width: 1024px) {
			padding: 12% 10%;
			&__space {
				font-size: 14px;
				b {
					font-size: 17px;
				}
			}
		}
		@media only screen and (min-width: 768px) {
			&__space {
				font-size: 12px;
				b {
					font-size: 16px;
				}
			}
		}
	}
}

.map-block {
	position: relative;
	padding-bottom: 30px;
	// height: 627px;
	// background: #f0ede5;
	&__main {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	&__content {
		position: relative;
		margin: 0 auto;
		max-width: 355px;
		width: 100%;
		height: auto;
		padding: 2em 2.5em;
		box-sizing: border-box;
		background: #fff;
		border-radius: 5px;
		box-shadow: 0 7px 25px rgba(36, 42, 44, 0.2);
		.link-reset {
			display: inline-block;
			vertical-align: top;
			margin: 20px 0 10px;
		}
	}	
	@media only screen and (min-width: 768px) {
		height: 627px;
		padding: 0;
		&__main {
			display: block;
		}
		&__content {
			position: absolute;
			top: 50%;
			height: 595px;
			right: 0;
			transform: translateY(-50%);
			box-shadow: 0 29px 45px rgba(36, 42, 44, 0.2);
		}
	}

	@media only screen and (min-width: 1280px) {
		&__content {
			right: 13%;
		}
	}

}

.actions-slider {
	.swiper {
		&-slide {
			width: 335px;
			transition: .25s ease;
			// &-fade,
			// &:not(.swiper-slide-visible) + .swiper-slide-visible {
			// 	opacity: 0.5;
			// }
		}
		&-pagination {
			position: relative;
			padding: 20px 0 40px;
			&-bullet {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				border: 1px solid #888d91;
				background: transparent;
				opacity: 1;
				transition: all 0.2s linear;
				&:hover {
					border-color: #fff;
				}
				&-active {
					background: #fff;
				}
			}
		}		
	}
}

.gallery-slider {
	position: relative;
	.swiper {
		&-slide {
			width: 335px;
			transition: .25s ease;
		}	
		&-button-prev,
		&-button-next {
			width: 33px;
			height: 63px;
			background: none;
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				margin: auto;
				width: 120%;
				height: 120%;
				// background: rgba(255,255,255,.8);
				transform: translate(-50%, -50%);
			}
			svg {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				width: 100%;
				height: 100%;
				fill: #c1c1c1;
				transition: fill 0.2s linear;
			}
			&:hover {
				svg {
					fill: #dcac6f;
				}
			}
			&:active {
				svg {
					fill: #263744;
				}
			}
		}
		&-button-prev {
			left: 2.6%;
		}
		&-button-next {
			right: 2.6%;
		}

	}
	@media only screen and (max-width: 768px) {
		padding-bottom: 80px;
		.swiper {
			&-button-prev,
			&-button-next {
				width: 20px;
				height: 40px;
				background: transparent;
				top: 100%;
				margin-top: -80px;
			}
			&-button-prev {
				left: 35%;
			}
			&-button-next {
				right: 35%;
			}
		}
	}
}

.plan-slider {
	position: relative;
	opacity: 1;
	// padding: 0 40px;
	.swiper {
		&-slide {
			// width: 246px;
			width: 180px;
			transition: .25s ease;
		}
		&-button-prev,
		&-button-next {
			width: 19px;
			height: 37px;
			background: none;
			svg {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				width: 100%;
				height: 100%;
				fill: #c1c1c1;
				transition: fill 0.2s linear;
			}
			&:hover {
				svg {
					fill: #dcac6f;
				}
			}
			&:active {
				svg {
					fill: #263744;
				}
			}
		}
		&-button-prev {
			left: 0px;
		}
		&-button-next {
			right: 0px;
		}
	}
}

.consultation {
	position: relative;
	z-index: 5;
	.list-advantages {
		padding-right: 60px;
		ul {
			// font-size: 0;
			line-height: 0;
			margin: 0 0 20px;
			padding: 0;
			list-style: none;
			.advantage-item {
				position: relative;
				display: inline-block;
				vertical-align: top;
				width: 49%;
				min-width: 300px;
				height: 4.5em;
				margin-bottom: 20px;
				padding: 0 0 0 86px;
				// padding: 0 0 0 10%;
				box-sizing: border-box;
				// cursor: pointer;
				&__icon {
					display: inline-block;
					position: absolute;
					top: 50%;
					left: 0;
					max-width: 64px;
					width: 22%;
					// padding-top: 4.5em;
					padding-top: 64px;
					border-radius: 50%;
					background: #2d4053;
					fill: #fff;
					transform: translateY(-50%);
					transition: all 0.2s linear;
					svg {
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						width: 60%;
						height: 60%;
						margin: auto;
					}
				}
				&__title {
					display: inline-block;
					vertical-align: middle;
					// padding: 11px 0;
					font: bold 12px/4.7em 'Verdana', Geneva, Tahoma, sans-serif;
					color: #2d4053;
					text-transform: uppercase;
					transition: color 0.2s linear;
					span {
						display: inline-block;
						vertical-align: middle;
						line-height: 1.5em;
						height: 3em;
						overflow: hidden;
					}
				}
				&:hover {
					.advantage-item {
						&__icon {
							background: #daa767;
							
						}
						&__title {
							color: #daa767;
						}						
					}
				}
			}
		}
	}
	@media only screen and (min-width: 1024px) {
		.list-advantages {
			ul {
				margin: 0;
				.advantage-item { 
					padding-left: 30%;
					display: block;
					width: 100%;
					min-width: 0;
					&__title {
						font-size: 13px;
					}	
					&__icon {
						padding-top: 22%;
					}		
				}				
			}
		}
	}

}

.catalog {
	position: relative;
	&__filter {
		position: relative;
		padding: 15px 30px;
		margin: 0 0 60px;
		background: #fff;
		border-radius: 5px;
		box-shadow: 0 29px 45px rgba(36, 42, 44, 0.2);
		.link-reset {
			display: inline-block;
			vertical-align: top;
			margin: 20px 0 10px;
		}
	}
	.filter {
		&__name {
			position: relative;
			margin: 0 0 1.6rem;
			padding: 0 0 25px;
			border-bottom: 1px solid #e5e5e5;
			p {
				margin: 0;
				font-weight: bold;
			    font-size: 12px;
			    text-transform: uppercase;
			    span {
			    	font-weight: 400;
			    }
			}			
		}
		&__dropdown-list {
			display: block;
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 0;
			opacity: 0;
			overflow: hidden;
			visibility: hidden;
			border: 1px solid #d1d1d1;
			border-bottom-right-radius: 4px;
			border-bottom-left-radius: 4px;
			background: #f2f3f5;
			z-index: 10;
			transition: all 0.2s linear;
		}
		.dropdown-list-item {
			position: relative;
			font-family: 'Verdana', Geneva, Tahoma, sans-serif;
			font-weight: 400;
			font-size: 13px;
			line-height: 2em;
			a, span {
				position: relative;
				display: block;
				padding: 0.4em 1.6em;
				text-decoration: none;
				color: #6a6a6a;
				transition: all 0.2s linear;
				cursor: pointer;
				&:hover {
					background: #eebe81;
					color: #fff;
				}
			}
			&.is-active {
				position: relative;
				display: block;
				width: 100%;
				border: 1px solid #d1d1d1;
				border-radius: 4px;
				background: #fff;
				&:before {
					content: '';
					position: absolute;
					top: 50%;
					right: 10px;
					width: 12px;
					height: 10px;
					background: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDQ1MS44NDcgNDUxLjg0NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDUxLjg0NyA0NTEuODQ3OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTIyNS45MjMsMzU0LjcwNmMtOC4wOTgsMC0xNi4xOTUtMy4wOTItMjIuMzY5LTkuMjYzTDkuMjcsMTUxLjE1N2MtMTIuMzU5LTEyLjM1OS0xMi4zNTktMzIuMzk3LDAtNDQuNzUxICAgYzEyLjM1NC0xMi4zNTQsMzIuMzg4LTEyLjM1NCw0NC43NDgsMGwxNzEuOTA1LDE3MS45MTVsMTcxLjkwNi0xNzEuOTA5YzEyLjM1OS0xMi4zNTQsMzIuMzkxLTEyLjM1NCw0NC43NDQsMCAgIGMxMi4zNjUsMTIuMzU0LDEyLjM2NSwzMi4zOTIsMCw0NC43NTFMMjQ4LjI5MiwzNDUuNDQ5QzI0Mi4xMTUsMzUxLjYyMSwyMzQuMDE4LDM1NC43MDYsMjI1LjkyMywzNTQuNzA2eiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=) 0 0 no-repeat;
					background-size: 100%;
					margin-top: -5px;
					z-index: 10;
					cursor: pointer;
				}
				span {
					&:hover {
						background: #f2f3f5;
						color: #000;
					}					
				}
				&.is-show + .filter__dropdown-list {
					opacity: 1;
					height: auto;
					visibility: visible;
				}
				// padding-right: 30px;
			}
		}
		


	}
	&__list {
		opacity: 1;
	}
}

.search-result {
	margin-bottom: 4%;
	p {
		font: 16px/1.6em 'Verdana', Geneva, Tahoma, sans-serif;
		color: #585858;
		span {
			color: #d19142;
		}
	}
}

.contacts {
	position: relative;
	margin-bottom: 75px;
	&-info {
		text-align: center;
		._border-top {}
		&__block {
			position: relative;
			display: inline-block;
			padding: 0 0 0 30px;
			margin-bottom: 25px;
			text-align: center;			
			> a {
				position: relative;
				font-size: 13px;
				color: #bd8846;
			}	
			> span {
				display: block;
				font: 14px/1.8em 'Roboto', Verdana, Geneva, Tahoma, sans-serif;
				color: #313131;
			}	
			ul {
				position: relative;
				padding: 0;
				margin: 0;
				list-style: none;
				li {
					position: relative;
					display: block;
					margin-bottom: 5px;
					a {
						display: inline-block;
					    vertical-align: top;
					    font: 18px/1.6em 'Verdana', Geneva, Tahoma, sans-serif;
					    color: #757575;
					    text-decoration: none;
					    transition: color 0.2s linear;	
					    span {
					    	color: #313131;
					    }					
					    &:hover {
					    	text-decoration: underline;
					    	span {
					    		color: #757575;
					    	}
					    }
					}
				}
			}	
		}
		&__title {
			position: relative;
			font: bold 12px/2em 'Verdana', Geneva, Tahoma, sans-serif;
			color: #000000;
			text-transform: uppercase;
			margin: 0 0 20px;			
		}			
		.svg-icon {
			display: block;
			position: absolute;
			top: 2px;
			left: -30px;
			width: 100%;
			height: 100%;
			max-width: 18px;
			max-height: 20px;
			fill: #daa767;
			svg {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0; 
				width: 100%;
				height: 100%;
				margin: auto;
			}
		}
	}
	&-map {
		margin-bottom: -60px;
		.ggl_map {
			position: relative;
			padding-top: 350px;
			// background: #e3e3e3;
			> div {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;				
			}
			@media only screen and (min-width: 720px) {
				padding-top: 400px;			
			}
			
			@media only screen and (min-width: 1024px) {
				padding-top: 457px;			
			}
		}


	}

	@media only screen and (min-width: 1024px) {
		&-info {
			max-width: 260px;
			&__block {
				display: block;
				text-align: left;
			}
		}
		._border-top {
			padding-top: 25px;
			border-top: 1px solid #e5e5e5;
		}
	}


}

.content {
	position: relative;
	// margin-bottom: 30px;
	> div:not(.content__link) {
		padding-bottom: 30px;
		border-bottom: 1px solid #e5e5e5;
	}
	&__link {
		position: relative;
		padding: 30px;
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAFAgMAAAA8mPSdAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAADFBMVEXap2f////ap2f///9CwI8jAAAAAnRSTlMAAHaTzTgAAAABYktHRAH/Ai3eAAAACXBIWXMAAAsSAAALEgHS3X78AAAAGklEQVQI12PgYGBgCA1gUIBQq0BgAYwHkQMAc4cG115qvfMAAAAASUVORK5CYII=') 0 0 no-repeat;
			width: 22px;
			height: 5px;
			margin-top: -2.5px;
		}
		a {
			font: 10px/1.4em 'Verdana', Geneva, Tahoma, sans-serif;
			color: #daa767;
			text-transform: uppercase;
		}
		@media only screen and (min-width: 720px) {
			a {
				font-size: 12px;				
			}
		}

	}
	.content-label {
		position: relative;
		display: inline-block;
		padding: 0 10px;
		margin: 0 0 1.4%;
		height: auto;
		z-index: 12;
		background: #1d2b34;
		border-radius: 15px;
		transition: 0.3s linear;
		&__icon {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			width: 30px;
			height: 30px;
			border-radius: 15px;
			background: #1f98dd;
			transition: 0.3s linear;
			svg {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				width: 60%;
				height: 60%;
				fill: #fff;
			}
		}
		&__text {
			display: inline-block;
			vertical-align: middle;
			padding: 9px 10px ;
			font: bold 9px/1.4em 'Verdana', Geneva, Tahoma, sans-serif;
			color: #ffffff;
			text-transform: uppercase;
		}

		@media only screen and (min-width: 600px) {
			padding: 0 10px 0 40px;
			height: 30px;
			&__icon {
				display: block;
			}
		}



		@media only screen and (min-width: 720px) {
			&__text {
				font-size: 10px;
			}
		}
		@media only screen and (min-width: 1024px) {
			&__text {
				font-size: 11px;
			}
		}


	}

	.content-date {
		position: relative;
		display: block;
		margin: 0 0 1.4%;
		padding: 10px 0;
		font: 11px/1.4em 'Verdana', Geneva, Tahoma, sans-serif;
		color: #919191;

		@media only screen and (min-width: 720px) {
			&__text {
				font-size: 11px;
			}
		}
		@media only screen and (min-width: 1024px) {
			&__text {
				font-size: 13px;
			}
		}

		@media only screen and (min-width: 1280px) {
			&__text {
				font-size: 14px;
			}
		}

	}
}

.form-order-service {
	position: relative;
	.title {
		margin-bottom: 4%;
	 	> span {
	 		font-size: 16px;
	 		color: #c5d1d9;
	 		margin-top: -0.5em;
	 	}
	}
	.view-form {
		background: transparent;
		box-shadow: none;
		&:before {
			content: none;
		}
	}
	.wrapper-form {
		max-width: 720px;
		margin: 0 auto;
		.form {
			&__col {
				width: 100%;			

			}
			&__group {
				&:last-child {
					margin-bottom: 1.6em;
				}
			}

			@media only screen and (min-width: 1024px) {
				&__col {
					& + .form {
						&__col {
							&:before {
								content: '';
								position: absolute;
								top: 0;
								left: -12%;
								width: 1px;
								height: 100%;
								background: #5d6161;
							}	
						}
					}
				}
					
			}

			@media only screen and (min-width: 720px) {
				&__col {
					max-width: 320px;
					width: 48%;
					& + .form {
						&__col {
							width: 48%;
							padding-left: 0;
						}
					}					
				}
				&__group {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			
		}
	}
}


.question-block {
	position: relative;
	background: #e8ebf0;
	padding: 5% 2.5%;
	// height: 212px;
	text-align: center;
	border-radius: 7px;
	box-shadow: 0 12px 53px rgba(39, 52, 58, 0.36);
	&__text {
		margin-bottom: 20px;
		p {
			font: bold 18px/1.2em 'Verdana', Geneva, Tahoma, sans-serif;
			color: #000000;
			text-transform: uppercase;
			margin: 0 0 10px;
		}
		span {
			display: block;
			font: italic 12px/1.4em 'Verdana', Geneva, Tahoma, sans-serif; 
			color: #606060;
		}		
	}
	&__phones {
		position: relative;
		text-align: center;
		ul {
			font-size: 0;
			line-height: 0;
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				display: block;
				margin-bottom: 10px;
				a {
					display: inline-block;
					vertical-align: top;
					font: 14px/1.4em 'Verdana', Geneva, Tahoma, sans-serif;
					color: #666666;
					text-decoration: none;
					b {
						font-size: 18px;
						color: #000;
						transition: color 0.2s linear;
					}	
					&:hover {
						b {
							color: #666666;							
						}
					}
				}
			}
		}
	}


	@media only screen and (min-width: 1366px) {		
		&__text {
			p {
				font-size: 25px;				
			}
			span {
				font-size: 18px;
			}			
		}
		&__phones {
			ul {
				li {
					a {
						font-size: 20px;
						b {
							font-size: 25px;
						}
					}
				}
			}
		}
	}

	@media only screen and (min-width: 1280px) {
		&__text {
			p {
				font-size: 24px;		 	
			}
			span {
				font-size: 17px;
			}			
		}
		&__phones {
			ul {
				li {
					a {
						font-size: 19px;
						b {
							font-size: 24px;
						}
					}
				}
			}
		}
	}

	@media only screen and (min-width: 1024px) {
		background: #e8ebf0 url('pic/bg_view-section--questions.jpg') center top no-repeat;
		height: 212px;
		&__text {
			text-align: right;
			padding: 0 100px 0 30px;
			margin-bottom: 0;
			p {
				font-size: 22px;			
			}
			span {
				font-size: 16px;
			}			
		}
		&__phones {
			ul {
				li {
					a {
						font-size: 17px;
						b {
							font-size: 22px;
						}
					}
				}
			}
		}
	}

	@media only screen and (min-width: 768px) {
		&__text {
			p {
				font-size: 20px;			
			}
			span {
				font-size: 14px;
			}			
		}
		&__phones {
			ul {
				li {
					a {
						font-size: 15px;
						b {
							font-size: 20px;
						}
					}
				}
			}
		}
	}

	


}

.jk {
	&-short-info {
		position: relative;
		&__left {}
		&__developer {
			position: relative;
			background: #fff;
			margin-bottom: 20px;
			box-shadow: 0 18px 39px rgba(0, 0, 0, 0.12);
			.developer {
				&-item {
					font-size: 0;
					line-height: 0;
					// padding: 30px;
					padding: 4.5%;
					border-top: 1px solid #e5e5e5;
					&:first-child {
						border: none;
					}
					> div {
						display: inline-block;
						vertical-align: middle;
						width: 50%;
					}
					&__title {
						position: relative;
						padding-left: 70px;
						.svg-icon {
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							padding-top: 50px;
							width: 50px;
							border-radius: 50%;
							background: #2d4053;
							fill: #fff;
							svg {
								display: block;
								position: absolute;
								top: 0;
								left: 0;
								bottom: 0;
								right: 0;
								width: 50%;
								height: 50%;
								margin: auto;
							}						

						}
						p {
							font: bold 11px/1.3em 'Verdana', Geneva, Tahoma, sans-serif;
							color: #2d4053;
							padding: 4px 0;
							text-transform: uppercase;
						}						
					}
					&__text {
						text-align: right;
						font: 12px/1.3em 'Verdana', Geneva, Tahoma, sans-serif;
						color: #000000;
					}					
				}
				@media only screen and (min-width: 1366px) {
					&-item {
						&__title {
							p {
								font-size: 13px;
							}
						}
						&__text {
							font-size: 16px;
						}
					}
				}

				@media only screen and (min-width: 1024px) {
					margin-bottom: 0;
					&-item {
						&__title {
							p {
								font-size: 12px;
							}
						}
						&__text {
							font-size: 14px;
						}
					}
				}

				@media only screen and (min-width: 768px) {
					&-item {
						&__text {
							font-size: 12px;
						}
					}
				}

			}
		}
		&__right {}
		&__order {
			position: relative;
			padding: 30px 4%;
			// padding: 4%;
			background: #fff;
			box-shadow: 0 18px 39px rgba(0, 0, 0, 0.12);
			// margin-bottom: 30px;
			margin-bottom: 4.3%;
			@media only screen and (min-width: 768px) {
				padding: 4%;
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 3px;
				height: 100%;
				background: #daa767;
			}
			&:after {
				content: none;
				position: absolute;
				top: 0;
				left: 50%;
				width: 1px;
				height: 100%;
				background: #e5e5e5;
				@media only screen and (min-width: 640px) {
					content: '';
				}

			}
			.order-block {
				text-align: center;
				&__price {
					font: bold 14px/1.2em 'Verdana', Geneva, Tahoma, sans-serif;
					color: #000000;
					margin-bottom: 15px;
					> span {
						font-size: 22px;
					}
				}
				&__btn {
					margin-bottom: 20px;
				}
				&__title {
					position: relative;
					padding-left: 100px;
					height: 100px;
					line-height: 100px;
					text-align: left;
					cursor: pointer;
					transition: all 0.2s linear;
					.svg-icon {
						display: block;
						position: absolute;
						top: 50%;
						left: 0;
						width: 80px;
						// width: 34%;
						padding-top: 80px;
						// padding-top: 34%;
						border-radius: 50%;
						background: #01b39c;
						fill: #fff;
						transform: translateY(-50%);
						svg {
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							bottom: 0;
							right: 0;
							width: 80%;
							height: 80%;
							margin: auto;							
						}
					}
					p {
						display: inline-block;
						vertical-align: middle;
						font: bold 11px/1.3em 'Verdana', Geneva, Tahoma, sans-serif;
						color: #000000;
						text-transform: uppercase;
						max-height: 3.9em;
						height: auto;
						overflow: hidden;
						margin: 0;
					}
					&:hover {
						opacity: 0.8;
					}
				}

				@media only screen and (min-width: 1366px) {
					&__price {
						font-size: 20px;
						> span {
							font-size: 35px;
						}
					}
					&__title {
						p {
							font-size: 14px;
						}
					}
				}

				@media only screen and (min-width: 1024px) {
					&__price {
						font-size: 18px;
						> span {
							font-size: 30px;
						}
					}
					&__title {
						p {
							font-size: 13px;
						}
						.svg-icon {
							width: 34%;
							padding-top: 34%;
						}
					}
				}

				@media only screen and (min-width: 768px) {
					&__btn {
						margin-bottom: 0;
					}
					&__price {
						font-size: 16px;
						> span {
							font-size: 26px;
						}
					}
					&__title {
						padding-left: 120px;
						p {
							font-size: 12px;
						}
						.svg-icon {
							width: 100px;
							padding-top: 100px;
						}
					}
				}


			}
		}
		&__plan {
			position: relative;
			text-align: center;
			> p {
				font: 14px/1.3em 'Verdana', Geneva, Tahoma, sans-serif;
				color: #000000;
				text-transform: uppercase;
			}
		}
	}
	&-benefits {
		position: relative;
		// padding: 0 60px;
		padding: 0 4%;
		&-item {
			position: relative;
			text-align: center;
			// margin-left: 50px;
			margin-bottom: 30px;
			&:nth-child(2) {
				text-align: center;
				margin-right: 0;
			}
			&__in {
				padding: 0;
				// position: absolute;
				// top: 0;
				// left: 0;
				// width: 100%;
				// height: 100%;
			}
			&--own-shopping-enter {
				// min-height: 301px;
				.jk {
					&-benefits {
						&-item {
							&__in {
								// padding-left: 296px;
								// padding-left: 23.6%;
							}
							&__img {
								// left: 0;
								max-width: 306px;
								width: 100%;
								padding-top: 301px;
								// padding-top: 23.8%;
							}
							&__title {
								// padding-top: 100px;
								// padding-top: 10%;
								// margin-left: 50px;
								// margin-left: 5%;

							}
							&__content {
								// padding-left: 50px;
								padding-left: 5%;
								// min-height: 30px;
							}
						}
					}
				}
			}

			&--location {
				// min-height: 273px;
				// padding-top: 273px;
				// margin-top: -130px;
				// margin-top: -10%;
				// text-align: right;		
				.jk {
					&-benefits {
						&-item {
							&__in {
								// padding-right: 273px;
								// padding-right: 21%;
							}
							&__img {
								// right: 0;
								max-width: 283px;
								width: 100%;
								padding-top: 273px;
								// padding-top: 21.3%;
							}
							&__title {
								// text-align: right;
								// padding-top: 120px;
								// padding-top: 12%;
								// margin-right: 50px;
								// margin-right: 5%;

							}
							&__content {
								// padding-right: 50px;
								padding-right: 5%;
								ul {
									li {
										display: inline-block;
										vertical-align: top;
										margin-left: 20px;
										text-align: center;
										p {
											position: relative;
											padding-left: 50px;
											text-align: left;
											font: bold 24px/1.1em 'Verdana', Geneva, Tahoma, sans-serif;
											color: #daa767;
											margin: 0 0 15px;
											display: inline-block;
											&:before {
												content: '';
												position: absolute;
												top: 0;
												left: 0;
												width: 32px;
												height: 32px;
											}
										}
										&:nth-child(1) {
											margin-left: 0;
											p {
												&:before {
													background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABF1BMVEXap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2ctQFMtQFMtQFMtQFMtQFPap2fap2fap2ctQFMtQFPap2fap2ctQFMtQFPap2fap2ctQFPap2ctQFPap2ctQFMtQFMtQFMtQFMtQFPap2ctQFMtQFPap2fap2ctQFMtQFPap2fap2fap2fap2ctQFMtQFPap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2ctQFP///9br8pGAAAAWnRSTlMADFeYyOn4ViKb85kIifn+9IjP/b1nKwNaLOfrEuokTHUtcU7t6CPO3ib236lXBiffh+FT+kmRAZp0kg1Uu/2L/qsrZrIvKMe4NQTyVQ++PCW67E28KgL1WMmWQOdTAAAAAWJLR0Rc6tgAlwAAAAlwSFlzAAALEgAACxIB0t1+/AAAAZhJREFUOMt1U+lawjAQXE6BIi2Uq0DRAoJ4gqKCeOOJ4AGisH3/93BTShvCx/zJfruTZLKZBXDg8fr8gWAw4PdteGAVoXAEHUSkkFCObsasiqwoshXE4tGl7QnKqclUOpPNZtKpJCMluEO0HCXyGgsLBSuhUyKnLerFAJW3tjV3h1EqVxB3qvb9NcTd+qK2t39weETrcQOxNtfRRNSLzmaTcHLKzs0jNlmm2kL5DJYIpnl+AVCmtzChbcRKRySYlwAdktEG6Cqopo0VgnkFhiGj0gUv4jXfErt+c0txEtELEmJqhXB3/8DiHqIEfsS0SHh8msfPiC+QRzWzTHh9W8R9GfMQw/fBkCN8fDrhcBDB2AqBw5wgXsGjr9IVJPJrHaHORNIze+sI1jOpUd/rCFajxFZzptCsVouf5cL+LPG7XdB3tyxPLRvGQVG3DQNRsnSjLtbrruUs7mhc4pQapfGIP9dgttfX294eHPmnN6HB+Z30rlVhcEhH3Bk9dT56zaggqhr+c4dXCYvDy+CZSjO91dJn0pQb/3+4WmVinENw6wAAAABJRU5ErkJggg==') 0 0 no-repeat;
												}
											}
										}
										&:nth-child(2) {
											p {
												&:before {
													background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABAlBMVEXap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2ctQFMtQFMtQFMtQFMtQFPap2fap2fap2ctQFMtQFPap2fap2ctQFMtQFPap2fap2fap2ctQFPap2ctQFMtQFPap2ctQFPap2fap2ctQFPap2fap2fap2fap2ctQFPap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2ctQFP///+MEOSjAAAAU3RSTlMADFeYyOn4ViKb85kIifn+9IjP/b1nKwNaLOfrEuokTHUtcU7t6CPO3ibz16ZWBiffh9tS+kmJA5p0DVS75FxmrCjH4ATyVQ/5JbrsTbwqAvVYyWpyFukAAAABYktHRFWTBLgzAAAACXBIWXMAAAsSAAALEgHS3X78AAABj0lEQVQ4y3VT61qCQBAdb6VCgKIoKhZqml21yNQsuphWmqXD+z9LAyrggufP7jdzdvfszBwAF5FoLJ44OEjEY4cRCCKZSqOLNJdk0vyR4GRESRKdjZDhd45nKSbn8kqhWCwo+ZxNyvouUUsUKKv2tlJxAhoFSuo2X01Q+vhE9U7otXoD8bS5eb+FeNbe5s4vLq+uab3pILbWOrqIWtU9bBFu7+x7y4hdO9I0ULyHHYJl9R4A6vQXW2gfsTFgCVYPYEAy+gBDCWVFDxCsR9B1EaUhRBFH/pJsCU+kNIcYBQ4xH0awngFMRA7iiEoo4QXgFfENyigXQgnvAGMRyyDgx2QaRviE6SSNQoDgw5rAPuHHWKYnSOTXPkLbFknfNPcRnG9Sob73EZxCsaX2DYXqlJptlodNs9h2e6B2G83gwLioapuBAZ5GutNm821v5BzubF7zKdVr85n/Xt0ee23/2G+MI/6YCzLO78IcyYxxSEfGtZ68tl6XZ0Q1U3+eeaUUa14bkSW30gxDW3FLn/3/AV4xZpXzOvjcAAAAAElFTkSuQmCC') 0 0 no-repeat;
												}
											}
										}
										&:nth-child(3) {
											p {
												&:before {
													background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABOFBMVEXap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2fap2ctQFMtQFMtQFMtQFMtQFPap2fap2fap2ctQFMtQFPap2fap2ctQFMtQFPap2fap2fap2ctQFPap2ctQFMtQFPap2ctQFPap2fap2ctQFPap2fap2fap2fap2ctQFMtQFMtQFMtQFMtQFMtQFPap2ctQFMtQFMtQFPap2ctQFMtQFMtQFMtQFMtQFMtQFMtQFMtQFMtQFMtQFPap2fap2fap2fap2fap2fap2fap2fap2fap2ctQFP///+khOWvAAAAZXRSTlMADFeYyOn4ViKb85kIifn+9IjP/b1nKwNaLOfrEuokTHUtcU7t6CPO3ibz16ZWBiffh9tS+kmJA5p0DVS75FxmrCjH4ATyVQ/5sfcc798lWquouhDjOvxVfZPBAQvsTbwqAvVYydiXiBMAAAABYktHRGdb0+mzAAAACXBIWXMAAAsSAAALEgHS3X78AAABtklEQVQ4y3WTZ1/CQAzGA4ICRVooq0DRgqK4UVyI4t6Ie4Mz/f4fwVxpS3vg86b9Jf+7y+WeANjyeId8/uFhv29oxAP9CgRDaCskBLh0eDRiZERJEo2fSDTsWh6jmBxPJFPpdCqZiDMo5thEyVAgq7DfXM4IqBTIKFY+76f02LjSW6EVihOIkyXz/CnE6bKVm5mdm1+g7+IS4lS3jgqimrcX66TlFbZvFrHCIqUqiqvgAnR9bR2gSHdhhW4gTmzygL4GsEllbADUJJSTWh+gb4GmiSjVwItYd7bEArap0jiiFwTExCBA3wFoIArgQ0wOBHYB9hD3IYtyaiBwAHAoYhYieHR8Mgg4hZPjEEb6gDMLODcB/oiLSxNo0hEyHUFFXrms0TKBa4AyK5Ku2XCb56YLtMxrUqNu3cBdk+XvrUbxrSY9PBLwBJpitJp/LKZnXX+xH4t/bqb115c347mrpX7DGHpnhlFNw0CYLL1UBk7lnuUMtt0pOCrVCp22c1+N2V793/bm4IgfjU8anK/PRl3mBofqiNqjJ3dHrxLmiioFv3vDKwX54WXy/Ai/arWq/go/jvH/A5BRjARnPUH2AAAAAElFTkSuQmCC') 0 0 no-repeat;
												}
											}
										}
										span {
											display: block;
											position: relative;
											padding: 15px 0;
											font: 12px/1.2em 'Verdana', Geneva, Tahoma, sans-serif;
											color: #646464;
											text-align: center;
											&:before {
												content: '';
												position: absolute;
												top: 0;
												left: 50%;
												width: 42px;
												height: 1px;
												background: #d9d9d9;
												margin-left: -21px;
											}
										}
									}
								}
							}
						}
					}
				}
			}

			&--infrastructure {
				// min-height: 310px;
				// padding-top: 310px;
				.jk {
					&-benefits {
						&-item {
							&__in {
								// padding-left: 355px;
								// padding-left: 28%;
							}
							&__img {
								// left: 0;
								// width: 382px;
								padding-top: 197px;
								max-width: 382px;
								max-width: 320px;

								// width: 30%;
								// padding-top: 16%;
							}
							&__title {
								// padding-top: 60px;
								// padding-top: 6%;
								// margin-left: 70px;								
								// margin-left: 7%;								
							}
							&__content {
								// padding-left: 70px;
								padding-left: 7%;
								ul {
									text-align: left;									
									li {
										position: relative;
										display: inline-block;
										vertical-align: top;
										// width: 50%;
										width: 100%;
										min-width: 300px;
										margin-bottom: 20px;
										box-sizing: border-box;
										&:nth-child(even) {
											padding-left: 0;
										}
										p {
											position: relative;
											display: block;
											font: 12px/1.4em 'Verdana', Geneva, Tahoma, sans-serif;
											color: #494949;
											white-space: nowrap;
											text-overflow: ellipsis;
											overflow: hidden;
											margin: 0;
											min-height: 30px;
											padding: 5px 0 5px 50px;
											.svg-icon {
												display: block;
												position: absolute;
												top: 0;
												left: 0;
												width: 30px;
												height: 30px;
												fill: #2d4053;
												svg {
													display: block;
													position: absolute;
													top: 0;
													left: 0;
													bottom: 0;
													right: 0;
													margin: auto;
													width: 100%;
													height: 100%;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

			&__img {
				position: relative;
				z-index: 3;
				width: 100%;
				max-width: 300px;
				margin: 0 auto;
				// background: #e5e5e5;
				> span {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-size: 100%;
					background-repeat: no-repeat;
				}
			}
			&__title {
				font: bold 20px/1.3em 'Verdana', Geneva, Tahoma, sans-serif;
				color: #010101;
				text-transform: uppercase;
				margin-bottom: 10px;
				p {
					margin: 0;
				}
				// max-width: 400px;
			}
			&__content {
				position: relative;
				display: inline-block;
				ul {
					font-size: 0;
					line-height: 0;
					list-style: none;
					margin: 0;
					padding: 25px 0 0;
				}
			}

			@media only screen and (min-width: 1360px) {
				&__title {
					font-size: 30px;
				}

				&--own-shopping-enter {
					&--location {
						.jk {
							&-benefits-item {
								&__content {
									min-height: 100px;
								}
							}
						}
					}
				}

				&--location {
					.jk {
						&-benefits-item {
							&__content {
								ul {
									li {
										margin-left: 100px;
										p {
											font-size: 35px;
										}
										span {
											font-size: 18px;
										}
									}	
								} 								
							}							
						}	
					}					
				}
				&--infrastructure {
					.jk {
						&-benefits-item {
							&__content {
								ul {
									li {
										p {
											font-size: 16px;
										}
									}	
								} 								
							}							
						}	
					}					
				}					
			}

			@media only screen and (min-width: 1280px) {
				&__title {
					font-size: 26px;
				}	
				&--location {
					.jk {
						&-benefits-item {
							&__content {
								ul {
									li {
										p {
											font-size: 32px;
										}
										span {
											font-size: 17px;
										}
									}	
								} 								
							}							
						}						
					}					
				}
				&--infrastructure {
					.jk {
						&-benefits-item {
							&__content {
								ul {
									li {
										p {
											font-size: 14px;
										}
									}	
								} 								
							}							
						}	
					}					
				}
			}

			@media only screen and (min-width: 1024px) {
				&__title {
					font-size: 24px;
				}

				&--own-shopping-enter {
					&--location {
						.jk {
							&-benefits-item {
								&__content {
									min-height: 40px;
								}
							}
						}
					}
				}
				&--location {
					.jk {
						&-benefits-item {
							&__content {
								ul {
									li {
										margin-left: 50px;
										p {
											font-size: 30px;
										}
										span {
											font-size: 16px;
										}
									}	
								} 								
							}							
						}						
					}					
				}
				&--infrastructure {
					.jk {
						&-benefits-item {
							&__content {
								ul {
									li {
										p {
											font-size: 13px;
										}
									}	
								} 								
							}							
						}	
					}					
				}
			}

			@media only screen and (min-width: 768px) {
				text-align: left;
				margin-bottom: 4.5%;
				&:nth-child(2) {
					text-align: right;
					margin-right: 5%;
				}
				&__img {
					position: absolute;
					top: 0;
				}

				&__title {
					font-size: 22px;					    
				}
				&__content {
					border-top: 1px solid #ecd3b3;
					min-width: 400px;

				}
				&--own-shopping-enter {
					& + .jk-benefits-item--location {
						margin-top: -10%;
					}
					.jk {
						&-benefits-item {
							&__in {
								padding-left: 23.6%;								
							}
							&__img {
								left: 0;
								width: 24%;
   								padding-top: 23.8%;
								max-width: none;
							}
							&__title {
								padding-top: 10%;	
								margin-left: 5%;															
							}
							&__content {
								min-height: 30px;
							}
						}
					}
				}

				&--location {
					.jk {
						&-benefits-item {
							&__in {
								padding-right: 21%;								
							}
							&__img {
								width: 24%;
   								padding-top: 23.8%;
								right: 0;
								max-width: none;
							}
							&__title {
								padding-top: 12%;
								margin-right: 5%;								
							}
							&__content {
								ul {
									li {
										margin-left: 30px;
										p {
											font-size: 26px;
										}
										span {
											font-size: 14px;
										}
									}	
								} 								
							}							
						}						
					}					
				}

				&--infrastructure {
					.jk {
						&-benefits-item {
							&__in {
								padding-left: 28%;								
							}
							&__img {
								width: 30%;
    							padding-top: 16%;
								left: 0;
								max-width: none;
							}
							&__title {
								padding-top: 6%;
								margin-left: 7%;																								
							}
							&__content {
								ul {
									li {
										width: 50%;
										&:nth-child(even) {
											padding-left: 5%;
										}
									}
								}
							}
						}
					}
				}

			}

		}
	}
}

.jk {
	&__hide-form {
		display: inline-block;
		position: fixed;
		top: auto;
		bottom: -2px;
		right: 1%;
		width: auto !important;
		max-width: 300px;
		z-index: 50;
		transform: none;
		display: none;
		.btn-order-consultation {
			position: relative;			
			background: #1d2b34;
			padding: 0.8em 1em;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			border: 1px solid #eee;
			transition: all 0.2s linear;
			cursor: pointer;
			box-sizing: border-box;
			text-align: center;
			white-space: nowrap;
			span {
				display: inline-block;
				vertical-align: middle;
				font: bold 10px/1.2em 'Verdana', Geneva, Tahoma, sans-serif;
				color: #fff;
			}
			&:hover {
				background: #000;
			}
		}
		.hidden-wrapper {
			position: absolute;
			top: 0;
			right: 80px;
		}

		@media only screen and (min-width: 1360px) {
			right: 5%;
			.btn-order-consultation {
				padding: 1.2em 1.5em;
				span {
					font-size: 16px;
				}				
			}
		}

		@media only screen and (min-width: 1024px) {
			right: 3%;
			.btn-order-consultation {
				span {
					font-size: 14px;
				}				
			}
		}
		@media only screen and (min-width: 768px) {
			display: block;
			.btn-order-consultation {
				span {
					font-size: 11px;
				}				
			}
		}

	}
}

.pluso {
	position: relative;
	padding: 20px 0 !important;
	.pluso-more {
		display: none !important;
	}
}

