////
/// @group _partials/view-header.scss
////


// scss
// ================================

.view-header {
	// position: relative;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	// background: rgba(37,37,37,.85);
	background: #212e38;
	// padding: 10px 0;
	z-index: 50;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	&__responsive {
		display: block;
		.btn-menu {
			position: relative;
			float: left;
			width: 60px;
			height: 60px;
			overflow: hidden;
			cursor: pointer;
			> a, 
			> span {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 10;
				transition: all 0.2s linear;
				svg {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 28px;
					height: 28px;
					margin: -14px 0 0 -14px;
					fill: #eebe81;
					transition: fill 0.2s linear;
				}
				&:hover {
					background: #eebe81;
					svg {
						fill: #212e38;
					}
				}
				&:active {
					opacity: .8;
					transform: scale(0.9);
				}
			}
		}
		.logo {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 160px;
			padding: 5px 0;
			transform: translate(-50%,-50%);
			span, a {
				display: block;
				width: 100%;
				height: 100%;
			}
		}
	}

	&__logo {
		max-width: 302px;
		// width: 80%;
		width: 100%;
		span {
			display: block;
			width: 100%;
		}
	}
	&__in {
		display: none;
		// padding: 0 50px;
		padding: 0 1%;
	}
	&__menu {
		position: relative;
		text-align: right;
		padding-right: 20px;
		ul {
			font-size: 0;
			line-height: 0;
			margin: 13px 0;
			padding: 0;
			list-style: none;
			li {
				display: inline-block;
				vertical-align: top;
				// margin: 0 18px;
				margin: 0 2.5%;
				a {
					position: relative;
					display: block;
					font: bold 11px/3.7em 'Verdana', Geneva, Tahoma, sans-serif;
					color: #c8c8c8;
					text-transform: uppercase;
					text-decoration: none;
					transition: all 0.2s linear;
					&:after {
						content: '';
						position: absolute;
						top: 100%;
						left: 0;
						width: 100%;
						height: 3px;
						background: #fff;
						opacity: 0;
						visibility: hidden;
						transition: all 0.2s linear;
					}
					&:hover {
						color: #fff;
						&:after {
							opacity: 1;
							visibility: visible;
						}
					}
					&.is-active {
						color: #fff;
						&:after {
							opacity: 0;
							visibility: hidden;
						}
					}
				}
			}
		}
	}
	&__phones {
		position: relative;
		padding-right: 0px;
		// text-align: right;		


		ul {
			display: none;
			// width: 50%;
			font-size: 0;
			line-height: 0;
			margin: 0;
			padding: 0;
			list-style: none;
			text-align: right;
			li {
				position: relative;
				display: block;
				a {
					display: inline-block;
					vertical-align: top;
					font: 14px/2.2em 'Verdana', Geneva, Tahoma, sans-serif;
					color: #b3babe;
					text-decoration: none;
					transition: color 0.2s linear;
					span {
						font-size: 16px;
						font-weight: bold;
						color: #ffffff;
					}	
					&:hover {
						color: #fff;
					}				
				}
			}
		}
		.callback {
			// position: relative;
			// top: auto;
			// right: auto;
			// transform: none;
			white-space: nowrap;
			.button {
				font-size: 0.7em;
			}
		}
	}
	@media only screen and (min-width: 1024px) {
		// padding: 10px 0;
		padding: 0;
		&__responsive {
			display: none;
		}
		&__in {
			display: block;
		}
		&__logo {
			// width: 100%;
			// padding-right: 20%;
			padding-right: 0;
		}
		&__menu {
			ul {
				li {
					a {
						font-size: 10px;
					}
				}
			}
		}
		&__phones {
			padding: 10px 0;
			text-align: center;
		}
	}

	@media only screen and (min-width: 1360px) {
		padding: 10px 0;
		&__phones {
			.callback {
				.button {
					font-size: 0.8em;
				}
			}
		}
	}

	@media only screen and (min-width: 1440px) {
		&__in {
			padding: 0 2.6%;
		}
		&__logo {
			width: 100%;
			padding-right: 0;
		}
		&__menu {
			ul {
				li {
					a {
						font-size: 11px;
						&:after {
							top: calc(100% + 21px);
						}
					}
				}
			}
		}
		&__phones {
			padding: 0;
			ul {
				display: block;
				width: 100%;
				text-align: center;
				margin: 0 0 5px;
				li {
					display: inline-block;
					vertical-align: middle;
					width: 48%;
				}
			}
			
			// padding: 0;
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				width: 1px;
				height: 92px;
				background: rgba(255,255,255,.16);
				transform: translateY(-50%);
			}		

		}
	}


	@media only screen and (min-width: 1780px) {
		&__phones {
			display: flex;
		    justify-content: space-between;
		    padding: 0 10px;
		    align-items: center;
		    ul {
		    	padding: 0 15px;
		    	margin: 0;
		    	li {
		    		display: block;
		    		width: 100%;
		    	}

		    }
		}
	}

	
}


.list-phones {
	position: relative;
	text-align: center;	
	margin: 0 0 20px;	
	ul {
		// width: 50%;
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			position: relative;
			display: block;
			a {
				display: inline-block;
				vertical-align: top;
				font: 16px/2.2em 'Verdana', Geneva, Tahoma, sans-serif;
				color: #000;
				text-decoration: none;
				transition: color 0.2s linear;
				span {
					font-size: 17px;
					font-weight: bold;
					color: #000;
				}	
				&:hover {
					opacity: 0.8;
				}				
			}
		}
	}
}