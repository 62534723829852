////
/// @group _partials/view-menu.scss
////


// scss
// ================================

	.view-menu { 
        position: relative;
        display: none;
        background: #212e38;
        transition: transform 0.4s ease;
    } 

    .mm-menu {
        // width: 80%;
        // min-width: 305px;
        // max-width: 320px;
        box-shadow: inset 0 0 0 2px #eebe81;
        .mm-navbar {
            &-top {
                height: 60px;  
                .mm-prev:before {
                    border-color: #fff;
                }              
            }
            background: #212e38;
            .mm-title {
            	position: relative;
                font: 18px/1.2em "Roboto", sans-serif;
			    color: #fff;
			    text-transform: uppercase;
                padding: 18px;
			    overflow: visible;
            }  
            .mm-btn {
                width: 40px;
                height: 40px;
                position: absolute;
                top: 48%;
                margin-top: -20px;
            }
        }
        .mm-listview {
        	margin-top: 0;
            background: #212e38;
            li {
                position: relative;
			    display: block;
			    text-align: left;
			    transition: background 0.2s linear, color 0.2s linear;
			    border-bottom: 1px solid #ebebeb;
			    &:before {
			    	content: '';
				    position: absolute;
				    top: 0;
				    left: 0;
				    width: 100%;
				    height: 100%;
				    background: #eebe81;
				    opacity: 0;
				    transition: opacity 0.2s linear;
			    }
                &:after {
                    left: 0 !important;
                    border-color: #ebebeb;
                    display: none;
                }
                a {
                    position: relative;
				    display: block;
				    font: 13px/1.6em 'Verdana', Geneva, Tahoma, sans-serif;
                    color: #fff;
				    text-decoration: none;
				    padding: 12px 30px;
				    z-index: 5;
				    transition: color 0.2s linear;
                    @media only screen and (min-width: 768px) {
                        font-size: 15px;
                    }

                    .mm-selected {
                        padding: 10px 0;
                    }
                    &.mm-next {
                    	position: absolute;
                    }
                }
                .mm-next {
    				background: rgba(255, 255, 255, 0.4);
    				transition: all 0.2s linear;
    			}
                &:hover {
                	a {
                		color: #fff;                		
                	}
                	&:before {
                		opacity: 1;
                	}
                	 .mm-next {
	    				background: rgba(255, 255, 255, .8);
	    			}
                }
                &.our-developers {
                    margin: 0 0 20px;
                    border: 2px solid #eebe81;
                }
                &.callback {
                    padding: 20px 0;
                    text-align: center;
                    &:hover {
                        &:before {
                            content: none;
                        }
                    }
                }
            }
            > li {
                > a, ._span {
                    white-space: normal;
                    text-overflow: none;
                }
                ._span ~ em.mm-counter {
                    display: none;
                }
            }
            em.mm-counter {
                font-family: 'Verdana', Arial, Helvetica, sans-serif;
                color: #000;
            }
            
        }
    }

    .mm-listview li a:active {
        background: #000;
    }

    .mm-navbar-bottom {
        background: #212e38 !important;
    }

    .mm-hasnavbar-top-1 .mm-panels {
        top: 60px;        
        > .mm-panel.mm-hasnavbar {
           padding-top: 60px;        
        }
    }

    // html.mm-opening .mm-slideout {
    //     transform: translate3d(305px, 0, 0);
    // }
