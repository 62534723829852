////
/// @group _partials/source-link.scss
////


// scss
// ================================

.source-link {
	display: inline-block;
	vertical-align: middle;
	font-size: 1.2rem;
	line-height: 1.2;
	padding-top: .25rem;
	margin-left: 1rem;
	font-family: monospace;
	opacity: .5;
	border-bottom: 1px dashed;
	cursor: pointer;

	&:hover {
		opacity: 1;
	}
}
