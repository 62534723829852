////
/// @group _partials/view-wrapper.scss
////


// scss
// ================================

.view-wrapper {
	background-color: #ffffff;
	position: relative;
	overflow-x: hidden;
	display: flex;
	flex-wrap: wrap;
	min-width: 100%;
	min-height: 100%;

	> .view-seo,
	> .view-footer,
	> .view-container {
		@include flexBasisMin( 100% );
	}

	> .view-container,
	> .mm-page {
		order: 0;

	}
	> .view-container {
		padding: 60px 0 0;		
		// transform: scale(0.95);
		// transition: all 0.7s 6s cubic-bezier(0.7,0,0.3,1);
	}


	> .view-seo {
		order: 1;
		z-index: 5;
		// padding: 50px 0;
		padding: 3.5em 0;
		background: #ebeff1;
		.view-text {
			color: #898a8b;
		}
	}

	> .view-footer {
		order: 2;
		align-self: flex-end;
	}
	@media only screen and (min-width: 1024px) {
		> .view-container {
			padding-top: 0;
		}
	}
}

body {
	// transform: translate3d(0,0,0);
	> .mm-page {
		@include flexBasisMin( 100% );
	}
}

.view-seo {
	padding: 2rem 0;
	opacity: 1;
}

